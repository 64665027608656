import React from 'react';
import Button from '@components/atoms/Button';
import {
  DeleteOutlined,
  PlusOutlined,
  PlusSquareOutlined,
  UploadOutlined
} from '@ant-design/icons';
import { Typography } from 'antd';
import { string } from 'prop-types';
import styled from '@lib/styled';
import { Image, AspectRatioBox, Box } from '@chakra-ui/core';
import Tile, { SizeProps } from '@components/atoms/Tile';
const { Text, Title } = Typography;
import { primaryColor, grayscaleFilter } from '@lib/theme';
import { MediaPayloadType, getSecureImageURL } from '@lib/media';
import ReactPlayer from 'react-player';
import TextTileContent from '@atoms/TextTileContent';

export function MediaTile({
  size = 150,
  item,
  buttons,
  grayscale,
  style,
  onClick,
  overlay = true,
  focused = false
}: any) {
  const filter = grayscale ? grayscaleFilter : {};
  const { url, type, text } = item || {};
  let thumbnailContent;
  switch (type) {
    case MediaPayloadType.IMAGE:
      thumbnailContent = (
        <Image
          style={{
            borderRadius: 8,
            ...filter
          }}
          size={`${size}px`}
          objectFit="cover"
          src={getSecureImageURL(url)}
          alt="item thumbnail"
        />
      );
      break;
    case MediaPayloadType.VIDEO:
      thumbnailContent = (
        <ReactPlayer
          url={url}
          playing={focused}
          muted={!focused}
          volume={focused ? 1 : 0}
          controls={false}
          width={size}
          height={size}
        />
      );
      break;

    case MediaPayloadType.TEXT:
      thumbnailContent = <TextTileContent item={item} size={size} />;
      break;

    default:
      thumbnailContent = (
        <Image
          style={{
            borderRadius: 8,
            ...filter
          }}
          size={`${size}px`}
          objectFit="cover"
          src={getSecureImageURL(url)}
          alt="Unknown content type"
        />
      );
  }

  return (
    <Tile
      style={style}
      size={size}
      overlay={overlay}
      onClick={onClick}
      buttons={buttons}
      content={thumbnailContent}
    />
  );
}

export function ReinforcerTile({
  size = 150,
  item,
  buttons,
  grayscale,
  onClick,
  overlay = true
}: any) {
  const filter = grayscale ? grayscaleFilter : {};
  const { url, name } = item || {};
  const content = (
    <Image
      style={{
        borderRadius: 8,
        ...filter
      }}
      size={`${size}px`}
      objectFit="cover"
      src={getSecureImageURL(url)}
      alt="item thumbnail"
    />
  );

  return (
    <Tile
      size={size}
      overlay={overlay}
      overlayTint={primaryColor(0.7)}
      inactiveOverlayOpacity={1}
      onClick={onClick}
      buttons={buttons}
      content={content}
    />
  );
}

export function EditMediaTile({ item, size, onDelete }: any) {
  return (
    <MediaTile
      size={size}
      grayscale={false}
      buttons={
        <Button
          size="large"
          onClick={onDelete}
          shape="circle"
          icon={<DeleteOutlined />}
        />
      }
      item={item}
    />
  );
}

export function AddFromTokens({ item, size, onAdd }: any) {
  return (
    <MediaTile
      size={size}
      grayscale={false}
      onClick={onAdd}
      buttons={
        <Button size="large" shape="circle" icon={<PlusSquareOutlined />} />
      }
      item={item}
    />
  );
}

export function ButtonTile({ size = 150, onClick, icon }: any) {
  return (
    <Tile
      size={size}
      onClick={onClick}
      overlay={true}
      content={
        <AdditemWrapper size={size}>
          <Button size="large" shape="circle" icon={icon} />
        </AdditemWrapper>
      }
    />
  );
}

export function ActionTile({ size = 150, onClick, content }: any) {
  return (
    <Tile
      size={size}
      onClick={onClick}
      overlay={true}
      buttons={content}
      content={<AdditemWrapper size={size}>{content}</AdditemWrapper>}
    />
  );
}

export function AddTile({ size = 150, onClick }: any) {
  return <ButtonTile size={size} onClick={onClick} icon={<PlusOutlined />} />;
}

export function UploadIconTile({ size = 150, onClick }: any) {
  return <ButtonTile size={size} onClick={onClick} icon={<UploadOutlined />} />;
}

const AdditemWrapper = styled.div<SizeProps>`
  min-height: ${({ size }) => `${size}px`};
  min-width: ${({ size }) => `${size}px`};
  display: flex;
  overflow: hidden;
  background: ${primaryColor(0.5)};
  justify-content: space-around;
  align-items: center;
`;
